import { PLAYSEE_LINKS } from './Constant'

export const emailMockData = {
  reset_password: {
    type: 1,
    uid: 'uid123',
    link: PLAYSEE_LINKS.platform
  },
  change_email_verification: {
    type: 2,
    uid: 'uid123',
    link: PLAYSEE_LINKS.platform
  },
  verification_email_when_account_create_or_upgrade: {
    type: 3,
    uid: 'uid123',
    link: PLAYSEE_LINKS.platform
  },
  contact_us_for_advertising: {
    type: 4,
    uid: 'uid123',
    description: 'description123\ndescription456\n\ndescription789',
    filenames: ['filename1', 'filename2'],
    advertising_id: 'advertising_id_123'
  },
  contact_us_for_billing: {
    type: 4,
    uid: 'uid123',
    description: 'description123\ndescription456\n\ndescription789',
    filenames: ['filename1', 'filename2'],
    billing_ref_number: 'billing_ref_number_123'
  },
  campaign_created: {
    type: 23,
    uid: 'uid123',
    advertising_id: '827364519384',
    link: PLAYSEE_LINKS.platform,
    campaign: {
      id: 'campaign_id_123',
      name: 'campaign_name_123',
      start_time: '1995/07/18'
    }
  },
  campaign_approved: {
    type: 5,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: 'campaign_id_123',
      name: 'campaign_name_123',
      start_time: '1995/07/18'
    }
  },
  campaign_rejected: {
    type: 6,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: '100200092469198414',
      name: 'campaign_name_123',
      start_time: '1995/07/18',
      report: {
        dangerous: true,
        illegal_service: false,
        pornography: false,
        violence: false,
        indecent: false,
        date_service: false,
        copyright: false,
        animal_protection: false,
        alcohol: true,
        gambling: false,
        financial: false,
        political: false,
        medical_service: false,
        heavy_shaking: false,
        meaningless: false,
        stable: false,
        high_resolution: false,
        colorful: true,
        brightness: false
      }
    }
  },
  account_suspension: {
    type: 7,
    uid: 'uid123'
  },
  account_suspended_by_payment_failure: {
    type: 8,
    uid: 'uid123',
    advertising_id: 'advertising_id_123'
  },
  campaign_was_reported_and_terminated: {
    type: 9,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: '100200092469198414',
      name: 'campaign_name_123',
      start_time: '1995/07/18'
    }
  },
  inactive_users: {
    type: 10,
    uid: 'uid123'
  },
  unfinished_ads: {
    type: 11,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: '100250170571413012'
    }
  },
  ad_starts: {
    type: 12,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: '100250170571413012',
      name: 'campaign_name_123',
      start_time: '1995/07/18'
    }
  },
  ad_ended: {
    type: 13,
    uid: 'uid123',
    advertising_id: 'advertising_id_123',
    campaign: {
      id: '100250170571413012',
      name: 'campaign_name_123',
      start_time: '1995/07/18'
    }
  },
  verification_email_when_create_ads: {
    type: 14,
    uid: 'uid123',
    link: PLAYSEE_LINKS.platform
  },
  dmca_trademark_submission_receipt: {
    type: 15,
    uid: 'uid123',
    case_number: 'case_number_123',
    trademark_owner: 'trademark_owner_123',
    email: 'email_123',
    trademarked_work: 'trademarked_work_123',
    infringement_content: 'infringement_content_123',
    trademark_number: 'trademark_number_123',
    trademark_jurisdiction: 'trademark_jurisdiction_123',
    reason: 'reason_123'
  },
  dmca_trademark_counter_notice_submission_receipt: {
    type: 16,
    uid: 'uid123',
    case_number: 'case_number_123',
    trademark_owner: 'trademark_owner_123',
    email: 'email_123',
    infringement_content: 'infringement_content_123',
    trademark_number: 'trademark_number_123',
    trademark_jurisdiction: 'trademark_jurisdiction_123',
    reason: 'reason_123'
  },
  dmca_trademark_counter_notice: {
    type: 17,
    uid: 'uid123',
    case_number: 'case_number_123',
    trademark_owner: 'trademark_owner_123',
    email: 'email_123',
    infringement_content: 'infringement_content_123',
    trademark_number: 'trademark_number_123',
    trademark_jurisdiction: 'trademark_jurisdiction_123',
    reason: 'reason_123'
  },
  dmca_copyright_submission_receipt: {
    type: 18,
    uid: 'uid123',
    case_number: 'case_number_123',
    copyright_owner: 'copyright_owner_123',
    email: 'email_123',
    copyrighted_work: 'copyrighted_work_123',
    infringement_content: 'infringement_content_123',
    reason: 'reason_123'
  },
  dmca_copyright_counter_notice_submission_receipt: {
    type: 19,
    uid: 'uid123',
    case_number: 'case_number_123',
    full_name: 'full_name_123',
    email: 'email_123',
    infringement_content: 'infringement_content_123',
    reason: 'reason_123'
  },
  dmca_copyright_counter_notice: {
    type: 20,
    uid: 'uid123',
    case_number: 'case_number_123',
    full_name: 'full_name_123',
    email: 'email_123',
    infringement_content: 'infringement_content_123',
    reason: 'reason_123'
  }
} as const
