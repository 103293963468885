import type { GetServerSideProps } from 'next'
import { useTranslation } from 'next-i18next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import { env } from 'assets/constant'
import { emailMockData } from 'assets/constant/emailMockData'
import { paymentMockData } from 'assets/constant/paymentMockData'
import Head from 'next/head'
import React from 'react'

const mockJson = {
  ...emailMockData,
  ...paymentMockData
}

const MyApp = () => {
  const { i18n } = useTranslation()
  const locale = i18n.language

  return (
    <div style={{ padding: '8px' }}>
      <Head>
        <title>Email template testing</title>
      </Head>
      <h1>Email template testing</h1>
      <dl>
        {Object.keys(mockJson).map(key => (
          <dd style={{ padding: '4px 0' }} key={key}>
            <form
              action={`${locale}/template`}
              method='POST'
              encType='text/plain'
            >
              {/* @ts-expect-error */}
              <span>{mockJson[key].type}: </span>
              <input
                type='hidden'
                name='data'
                // @ts-expect-error
                value={JSON.stringify(mockJson[key])}
              />
              <button type='submit'>{key}</button>
            </form>
          </dd>
        ))}
      </dl>
    </div>
  )
}

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  if (env === 'master') {
    return {
      notFound: true
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(locale ?? 'en', ['translation']))
    }
  }
}

export default MyApp
