export const paymentMockData = {
  paymentSuccessful: {
    type: 10,
    uid: 'User ID',
    advertising_id: 'EGFLRK',
    reference_id: '1078635472910',
    description: 'Playsee Ads',
    status: 1,
    payment_method: 3,
    payment_details: {
      coupon_id: 'cpn_c415rva23akg02lbd1b0',
      promocode_id: 'promo_c415rva23akg02lbd1bg',
      code: 'QA2107291551'
    },
    charged_at: '2021-07-01T18:48:26.122247137+08:00',
    billing_range: {
      start: '2021-07-02T18:48:26.122247137+08:00',
      end: '2021-07-03T12:02:34.794185716+08:00'
    },
    settlements: [
      {
        campaign_id: '1000000110',
        campaign_name: 'Campaign Name',
        amount: 3450,
        currency: 'USD'
      },
      {
        campaign_id: '1000000110',
        campaign_name: 'Campaign Name',
        amount: 130.23000000002,
        currency: 'USD'
      }
    ],
    amount: 0,
    currency: 'USD'
  },
  paymentFailed: {
    type: 10,
    uid: 'User ID',
    advertising_id: 'EGFLRK',
    reference_id: '1078635472910',
    description: 'Playsee Ads',
    status: 0,
    payment_method: 4,
    payment_details: {
      payer_id: 'W58R39RJCC9TW',
      email: 'kentpon.wang+paypaltest01@framy.co'
    },
    charged_at: '2021-07-20T18:48:26.122247137+08:00',
    billing_range: {
      start: '2021-07-20T18:48:26.122247137+08:00',
      end: '2021-07-29T12:02:34.794185716+08:00'
    },
    settlements: [
      {
        campaign_id: '1000000110',
        campaign_name: 'Campaign Name',
        amount: 12.12,
        currency: 'USD'
      },
      {
        campaign_id: '1000000110',
        campaign_name: 'Campaign Name',
        amount: 487,
        currency: 'USD'
      }
    ],
    amount: 520,
    currency: 'USD'
  }
} as const
