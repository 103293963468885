export type Env = 'local' | 'develop' | 'stage' | 'beta' | 'master'
export const env = process.env.NEXT_PUBLIC_ENVIRONMENT as Env

export const PAGE_TYPE = {
  reset_password: 1,
  change_email_verification: 2,
  verification_email_when_account_create_or_upgrade: 3,
  contact_us: 4,
  campaign_approved: 5,
  campaign_rejected: 6,
  account_suspension: 7,
  account_suspended_by_payment_failure: 8,
  campaign_was_reported_and_terminated: 9,
  payment_successful: 10,
  payment_unsuccessful: 11,
  inactive_users: 12,
  unfinished_ads: 13,
  adStarts: 14,
  adEnded: 15,
  verification_email_when_create_ads: 16,
  dmca_trademark_submission_receipt: 17,
  dmca_trademark_counter_notice_submission_receipt: 18,
  dmca_trademark_counter_notice: 19,
  dmca_copyright_submission_receipt: 20,
  dmca_copyright_counter_notice_submission_receipt: 21,
  dmca_copyright_counter_notice: 22,
  campaign_created:23,
} as const

export const PAYMENT_STATUS = {
  successful: 1,
  failed: 0
}

export const BUTTON_TYPE = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary'
}

type LinkPrefix = 'about' | 'business' | 'help' | 'careers'
type LinkEnv = 'co' | 'beta' | 'dev'
type BuildLink<Prefix extends LinkPrefix> = Record<
  Prefix,
  `https://${Prefix}.playsee.${LinkEnv}/`
>
type Links = BuildLink<'about'> &
  BuildLink<'business'> &
  BuildLink<'help'> &
  BuildLink<'careers'> & { platform: `https://playsee.${LinkEnv}/` }

export const PLAYSEE_LINKS = new Proxy(
  {},
  {
    get: (_, key: LinkPrefix | 'platform') => {
      const suffix = env === 'master' ? 'co' : env === 'beta' ? 'beta' : 'dev'
      const domain = key === 'platform' ? 'playsee' : `${key}.playsee`
      return `https://${domain}.${suffix}/`
    }
  }
) as Links

export const LEGAL_LINKS = {
  rules: `${PLAYSEE_LINKS.platform}rules`,
  privacy: `${PLAYSEE_LINKS.platform}privacy`,
  terms: `${PLAYSEE_LINKS.platform}user-terms`,
  adTerms: `${PLAYSEE_LINKS.platform}ad-terms`
} as const

export const REDIRECT_APP_LINK = 'https://love.playsee.co/'

export const URL = {
  contactUs: `${PLAYSEE_LINKS.business}contact`,
  termsOfService: `${PLAYSEE_LINKS.platform}user-terms`,
  advertisingTerms: `${PLAYSEE_LINKS.platform}ad-terms`,
  advertisingPolicies: `${PLAYSEE_LINKS.platform}ad-policies`,
  privacyAndCookies: `${PLAYSEE_LINKS.platform}privacy`,
  openApp: REDIRECT_APP_LINK,
  adsInsight: `${PLAYSEE_LINKS.business}manage/ads/insight`,
  createAds: `${PLAYSEE_LINKS.business}builder`,
  helperCenter: `${PLAYSEE_LINKS.help}playsee`,
}

export const MAIL_URI = {
  contactUs: `mailto:contact@playsee.co`,
}

export const SUBJECTS_TRANSLATION_KEYS = {
  [PAGE_TYPE.reset_password]: 'subject.reset_password',
  [PAGE_TYPE.change_email_verification]: 'subject.change_email_verification',
  [PAGE_TYPE.verification_email_when_account_create_or_upgrade]:
    'subject.verification_email_when_account_create_or_upgrade',
  [PAGE_TYPE.contact_us]: 'subject.contact_us',
  [PAGE_TYPE.campaign_created]: 'subject.campaign_created',
  [PAGE_TYPE.campaign_approved]: 'subject.campaign_approved',
  [PAGE_TYPE.campaign_rejected]: 'subject.campaign_rejected',
  [PAGE_TYPE.account_suspension]: 'subject.account_suspension',
  [PAGE_TYPE.account_suspended_by_payment_failure]:
    'subject.account_suspended_by_payment_failure',
  [PAGE_TYPE.campaign_was_reported_and_terminated]:
    'subject.campaign_was_reported_and_terminated',
  [PAGE_TYPE.payment_successful]: 'subject.payment_successful',
  [PAGE_TYPE.payment_unsuccessful]: 'subject.payment_unsuccessful',
  [PAGE_TYPE.inactive_users]: 'subject.inactive_users',
  [PAGE_TYPE.unfinished_ads]: 'subject.unfinished_ad',
  [PAGE_TYPE.adStarts]: 'subject.ad_starts',
  [PAGE_TYPE.adEnded]: 'subject.ad_ended',
  [PAGE_TYPE.verification_email_when_create_ads]:
    'subject.change_email_verification',
  [PAGE_TYPE.dmca_copyright_submission_receipt]:
    'subject.dmca_copyright_submission_receipt',
  [PAGE_TYPE.dmca_copyright_counter_notice_submission_receipt]:
    'subject.dmca_copyright_counter_notice_submission_receipt',
  [PAGE_TYPE.dmca_copyright_counter_notice]:
    'subject.dmca_copyright_counter_notice',
  [PAGE_TYPE.dmca_trademark_submission_receipt]:
    'subject.dmca_trademark_submission_receipt',
  [PAGE_TYPE.dmca_trademark_counter_notice_submission_receipt]:
    'subject.dmca_trademark_counter_notice_submission_receipt',
  [PAGE_TYPE.dmca_trademark_counter_notice]:
    'subject.dmca_trademark_counter_notice'
} as const

export const ERROR_TYPE = {
  TYPE_IS_REQUIRED: 1,
  PAYLOAD_IS_REQUIRED: 2,
  INVALID_TYPE: 3
}

export const ERRORS = {
  [ERROR_TYPE.TYPE_IS_REQUIRED]: 'type is required',
  [ERROR_TYPE.PAYLOAD_IS_REQUIRED]: 'payload is required',
  [ERROR_TYPE.INVALID_TYPE]: 'invalid type'
}

export const CDN_URL = 'https://storage.googleapis.com/playsee-web'